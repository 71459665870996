// @flow

import React from "react";
import { Form, Grid, Card, Button, Icon, Page } from "tabler-react";
import Select from "react-select";
import clientService from "../../../services/client";
import { validate } from "../../../validate";
import { toast, ToastContainer } from "react-toastify";
import Buttonloader from "../../../common/Loader/Buttonloader";
import "react-toastify/dist/ReactToastify.css";
import { changeLanguage } from "../../../language";
import { getCategoryById, updateCategory } from "../../../services/campaign";
import SiteWrapperCampaignSuperAdmin from "../SiteWrapper.campaign.superadmin.react";

const validationRules = [
  {
    field: "name_en",
    validations: ["required"],
    name: "Name",
  },
  {
    field: "name_ar",
    validations: ["required"],
    name: "Name",
  },
];

class CategoryEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        name_en: "",
        name_ar: "",
        _id: "",
      },
      message: {
        style: "success",
        text: "",
      },
      isLoading: false,
      errors: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);
  }

  setLoadingToggle(value) {
    this.setState({ isLoading: value });
  }

  //handle change
  handleChange = (e, field) => {
    const { fields } = this.state,
      val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
    this.validate();
  };

  handleNotification() {
    this.setState(() => ({ message: { text: "", style: "" } }));
  }

  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }

  componentDidMount() {
    document.title = "Voiceoc | Edit Campaign";
    this.setLoadingToggle(true);
    getCategoryById(this.props.match.params.id)
      .then((res) => {
        console.log("res", res);
        this.setState({
          fields: {
            _id: res.data._id,
            name_en: res.data.name_en,
            name_ar: res.data.name_ar,
          },
        });
        this.setLoadingToggle(false);
      })
      .catch((error) => {
        if (error.response && error.response.data.message)
          toast.error(error.response.data.message);
        else if (error.message) toast.error(error.message);
        else toast.error(error);
        this.setLoadingToggle(false);
      });
  }

  //submit form
  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.validate()) {
      this.setLoadingToggle(true);
      let data = {
        name_en: this.state.fields.name_en,
        name_ar: this.state.fields.name_ar,
        id: this.state.fields.id,
      };
      updateCategory(data, this.props.match.params.id)
        .then((response) => {
          this.setLoadingToggle(false);
          toast.success("Category updated successfully.");
          setTimeout(() => {
            this.props.history.push("/admin/campaigntabs/category/1");
          }, 3000);
        })
        .catch((error) => {
          this.setLoadingToggle(false);
          if (error.response && error.response.data.message)
            toast.error(error.response.data.message);
          else if (error.message) toast.error(error.message);
          else toast.error(error);
        });
    }
  };

  render() {
    return (
      <SiteWrapperCampaignSuperAdmin>
        <Page.Content>
          <Grid.Row>
            <Grid.Col lg={5} className="mx-auto ">
              <Card>
                <Card.Header>
                  <Card.Title>Edit Campaign</Card.Title>
                </Card.Header>
                <Card.Body>
                  <form onSubmit={this.handleSubmit}>
                    <Form.FieldSet>
                      <Form.Group
                        isRequired
                        label={changeLanguage(
                          this.props.siteLanguage,
                          "name_en"
                        )}
                      >
                        <Form.Input
                          name="name_en"
                          onChange={(e) => this.handleChange(e, "name_en")}
                          placeholder={changeLanguage(
                            this.props.siteLanguage,
                            "name_en"
                          )}
                          value={this.state.fields.name_en}
                        />
                        <label
                          style={{
                            display: this.state.errors.name_en
                              ? "block"
                              : "none",
                          }}
                          className="error"
                        >
                          {this.state.errors.name_en}
                        </label>
                      </Form.Group>
                      <Form.Group
                        isRequired
                        label={changeLanguage(
                          this.props.siteLanguage,
                          "name_ar"
                        )}
                      >
                        <Form.Input
                          name="name_ar"
                          onChange={(e) => this.handleChange(e, "name_ar")}
                          placeholder={changeLanguage(
                            this.props.siteLanguage,
                            "name_ar"
                          )}
                          value={this.state.fields.name_ar}
                        />
                        <label
                          style={{
                            display: this.state.errors.name_ar
                              ? "block"
                              : "none",
                          }}
                          className="error"
                        >
                          {this.state.errors.name_ar}
                        </label>
                      </Form.Group>
                    </Form.FieldSet>
                    {!this.state.isLoading ? (
                      <Button color="primary btn-block" type="submit">
                        Update
                      </Button>
                    ) : (
                      <Buttonloader
                        loading={this.state.isLoading}
                      ></Buttonloader>
                    )}
                  </form>
                  <ToastContainer autoClose={5000} position={"bottom-right"} />
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapperCampaignSuperAdmin>
    );
  }
}

export default CategoryEdit;
