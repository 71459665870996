// @flow

import * as React from "react";
import { NavLink, withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { Site, RouterContextProvider, Nav, Container } from "tabler-react";
import authActions from "../../redux/auth/action";
import $ from "jquery";
// import type { NotificationProps } from "tabler-react";
import ReactHtmlParser from "react-html-parser";

import { changeLanguage } from "../../language/index";
import SiteWrapperSuperadminReact from "../SiteWrapper.superadmin.react";

class SiteWrapperHospitalSuperAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      siteLanguage: "",
      showLang: "ENG",
      user: this.props.user,
    };
    // this.props.setLang({"siteLanguage": 'en'})
  }

  getMenu() {
    let navBarItems;
    navBarItems = [
      {
        value: "Category",
        icon: "minus-square",
        subItems: [
          {
            value: changeLanguage(this.props.siteLanguage, "view_category"),
            to: "/admin/campaigntabs/category/1",
            LinkComponent: withRouter(NavLink),
          },
          {
            value: changeLanguage(this.props.siteLanguage, "add_category"),
            to: "/admin/campaigntabs/category/add",
            LinkComponent: withRouter(NavLink),
          },
        ],
      },
      {
        value: "Campaign",
        icon: "file",
        subItems: [
          {
            value: changeLanguage(this.props.siteLanguage, "view_campaign"),
            to: "/admin/campaigntabs/campaign/1",
            LinkComponent: withRouter(NavLink),
          },
          {
            value: changeLanguage(this.props.siteLanguage, "add_campaign"),
            to: "/admin/campaigntabs/campaign/add",
            LinkComponent: withRouter(NavLink),
          },
        ],
      },
    ];
    return navBarItems;
  }
  componentDidMount() {
    this.menuSelection();
    //const { user } = this.props;
    // if (user.role === "client") {
    //   this.props.history.push('/client/dashboard');
    // }
    // this.props.logOut();
    //this.props.history.push('/');
  }

  componentDidUpdate() {
    this.menuSelection();
  }

  menuSelection() {
    var url = window.location.pathname;
    let splitUrl = url.split("/");

    if (
      splitUrl.length &&
      splitUrl[2] !== undefined &&
      splitUrl[2] === "campaigntabs"
    ) {
      if (splitUrl[3] !== undefined) {
        $(".nav-link").each(function (index, ele) {
          if ($(ele).text() == " Category" && splitUrl[3] == "category") {
            $(ele).addClass("active");
          } else if (
            $(ele).text() == " Campaign" &&
            splitUrl[3] == "campaign"
          ) {
            $(ele).addClass("active");
          }
          // else if($(ele).text() == ' Specialization' && splitUrl[2]=='specialization'){
          //     $(ele).addClass("active");
          // }
          // else if($(ele).text() == ' Doctor' && splitUrl[3]=='doctor'){
          //     $(ele).addClass("active");
          // }
        });
      }
    }
  }

  render() {
    return (
      <SiteWrapperSuperadminReact>
        <div className="container" style={{ backgroundColor: "white" }}>
          <Nav itemsObjects={this.getMenu()} />
          {this.props.children}
        </div>
      </SiteWrapperSuperadminReact>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    siteLanguage: state.auth.siteLanguage,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => {
      dispatch(authActions.authLogout());
    },
    setLang: (payload) => {
      dispatch(authActions.setLang(payload));
    },
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SiteWrapperHospitalSuperAdmin)
);
