import axios from "../http";
const API_URL = process.env.REACT_APP_apiUrl;

export async function createCategory(fields) {
  const url = `${API_URL}admins/category`;
  let response = await axios.post(url, fields);
  return response.data;
}

export async function getCategory(page, search = false, limit = 10) {
  let params = "page=" + page + "&limit=" + limit;
  if (search) {
    params += "&search=" + search;
  }
  const url = `${API_URL}admins/category?` + params;
  let response = await axios.get(url);
  return response.data;
}

export async function getAllCategories() {
  const url = `${API_URL}admins/category`;
  let response = await axios.get(url);
  return response.data;
}

export async function deleteCategory(id) {
  const url = `${API_URL}admins/category/` + id;
  let response = await axios.delete(url);
  return response.data;
}

export async function updateCategory(fields, id) {
  const url = `${API_URL}admins/category/` + id;
  let response = await axios.patch(url, fields);
  return response.data;
}

export async function getCategoryById(id) {
  const url = `${API_URL}admins/category/` + id;
  let response = await axios.get(url);
  return response.data;
}

export async function createCampaign(fields) {
  const url = `${API_URL}admins/campaign`;
  let response = await axios.post(url, fields);
  return response.data;
}

export async function getCampaign(page, search = false, limit = 10) {
  let params = "page=" + page + "&limit=" + limit;
  if (search) {
    params += "&search=" + search;
  }
  const url = `${API_URL}admins/campaign?` + params;
  let response = await axios.get(url);
  return response.data;
}

export async function getAllCampaign() {
  const url = `${API_URL}admins/campaign`;
  let response = await axios.get(url);
  return response.data;
}

export async function deleteCampaign(id) {
  const url = `${API_URL}admins/campaign/` + id;
  let response = await axios.delete(url);
  return response.data;
}

export async function updateCampaign(fields, id) {
  const url = `${API_URL}admins/campaign/` + id;
  let response = await axios.patch(url, fields);
  return response.data;
}
export async function updateCampaignImage(fields, id) {
  const url = `${API_URL}admins/campaign/updateCampaignImage/` + id;
  let response = await axios.patch(url, fields);
  return response.data;
}
export async function updateCampaignImageAr(fields, id) {
  const url = `${API_URL}admins/campaign/updateCampaignImageAr/` + id;
  let response = await axios.patch(url, fields);
  return response.data;
}

export async function getCampaignById(id) {
  const url = `${API_URL}admins/campaign/` + id;
  let response = await axios.get(url);
  return response.data;
}
