// @flow

import React from "react";
import {
  Icon,
  Grid,
  Card,
  Table,
  Form,
  Button,
  Page
} from "tabler-react";
import { Link } from 'react-router-dom'
import SiteWrapperSuperAdmin from "../SiteWrapper.superadmin.react";
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authActions from "../../redux/auth/action";
import clientService from "../../services/client";
import botService from "../../services/bot";
import DownloadPopup from './downloadPopup';
import { changeLanguage } from '../../../src/language/index';

import socketIOClient from 'socket.io-client';
var { jwtToken } = localStorage;
// var ENDPOINT = "https://waagentportal.rpsapi.in";
var ENDPOINT = process.env.REACT_APP_baseUrl;
var socket = socketIOClient(ENDPOINT, { query: `authorization=${jwtToken}` });
// import Loader from '../../common/Loader/loader'

class Clients extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.props.match.params.page,
      nextPage: false,
      prevoiusPage: false,
      clients: [],
      totalRecords: 0,
      search: '',
      isLoading: true,
      openDownloadPopup: false,
      agentLogs: [],
    }
    this.search = this.search.bind(this);
    this.closeDownloadPopup = this.closeDownloadPopup.bind(this);
  }

  closeDownloadPopup(data) {

    this.setState({
      openDownloadPopup: {
        open: false,
        data: false
      }
    })
  }

  openDownloadPopup(e, data) {
    this.setState({
      openDownloadPopup: {
        open: true,
        data: data,
      }
    })
  }

  componentDidMount() {
    if (!this.props.isLoggedIn) {
      this.props.history.push('/login');
    }
    document.title = 'Voiceoc | Client List';
    socket.on('connect', () => {
      console.log('connect', socket);
    });

    socket.on("chatting", data => {
      if (data.event === 'admin-login-status') {
        this.callService(this.state.currentPage);
      }
      if (data.event === 'admin-engage-score') {
        console.log('najmu999999999')
        this.callService(this.state.currentPage);
      }
    });
    socket.on('disconnect', function () {
      console.log('DISCONNESSO!!! ');
      socket.on('connect', () => {
        console.log('got to connect again', socket);
      });
    });
    this.callService(this.state.currentPage);

  }

  //handle change
  handleChange = (e, field) => {
    this.setState({ search: e.target.value });
  }

  setLoadingToggle(value) {
    this.setState({ isLoading: value })
  }

  //delete admin
  removeToAdmin = (event, admin) => {
    console.log(admin)
    event.preventDefault();
    const { currentPage } = this.state;
    const { search } = this.state

    this.setLoadingToggle(true);
    clientService.deleteAdmin(admin._id)
      .then(response => {
        toast.success("Agent Deleted Successfully.")
        this.callService(currentPage, search);
      })
      .catch(error => {
        this.setState(() => ({ message: { text: error.response.data.message, style: 'danger' } }));
        setTimeout(() => {
          this.handleNotification();
        }, 3000)
        this.setLoadingToggle(false);
      })

  }

  //auto hide notification
  handleNotification() {
    this.setState(() => ({ message: { text: '', style: '' } }));
  }

  endChat(clientId) {
    console.log('clientIdclientId', clientId)
    clientService.endChatClient(clientId)
      .then(response => {
        console.log('responseresponse', response)
        toast.success("Chat End Successfully.")

      })
  }
  //handle block unblock
  handleBlock(e, client, sticky = false) {
    var toggleData = client.status === 'active' ? 'block' : 'active'
    const isSticky = client.isSticky ? false : true
    const { currentPage } = this.state;
    const { search } = this.state
    let data = {}

    if (sticky) {
      data.isSticky = isSticky
    } else {
      data.status = toggleData
    }
    clientService.blockUnblock(client._id, data)
      .then(response => {
        if (sticky) {
          if (client.isSticky) {
            sticky = 'Deactivated';
          } else {
            sticky = 'Activated';
          }
          toast.success("Sticky Agent " + sticky + " Successfully.")
        }
        else {
          if (toggleData === 'active') {
            toggleData = 'Activated';
          } else {
            toggleData = 'Blocked';
          }
          toast.success("Agent " + toggleData + " Successfully.")
        }
        this.callService(currentPage, search);
      })
      .catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message)
        }
        //console.log(error,error.response)

      })
  }

  callService(page, search = false) {
    console.log('callService function called')

    clientService.getClients(page, search)
      .then(res => {
        if (res.statusCode === 200) {
          console.log(res.data)
          this.setState(() => ({
            clients: res.data.result,
            nextPage: res.data.next,
            totalRecords: res.data.total,
            prevoiusPage: this.state.currentPage > 1 ? true : false,
          }));
        }
      })
  }

  pagination(e, page) {
    this.callService(page);
    this.props.history.push("/admin/clients/" + page);
  }

  search(clear = false) {
    if (clear) {
      this.setState({
        search: ''
      });
    }
    setTimeout(() => {
      this.callService(1, this.state.search);
    }, 100);

  }

  handleViewClient(path, client) {
    this.props.adminLoginAsClient(client);
  }



  getPagination() {
    var page = this.props.match.params.page;
    var startRecord = this.props.match.params.page == 1 ? this.props.match.params.page : ((10 * (this.props.match.params.page - 1)) + 1)
    var endRecord = (parseInt(startRecord) + parseInt(this.state.clients.length) - 1);
    return startRecord + '-' + endRecord + ' of ' + this.state.totalRecords;
  }
  styles = {
    modalWrapper: {
      display: 'none',
    },
    modal: {
      width: '50%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#ffffff',
      boxShadow: '0 28px 48px rgba(0, 0, 0, 0.4)',
      zIndex: '12'
    }
  }
  closeModal() {
    document.getElementsByClassName("BackgroundBlur")[0].style.display = 'none';
  }
  openModal(email) {
    this.setState({ selectedEmail: email })
    clientService.getAgentLogs(email).then(res => {
      if (res.message === "Success" && res.statusCode === 200) {
        console.log(res.data.result, "DATA")
        let data = res.data.result.filter(f => new Date(f.time).toLocaleDateString() === new Date().toLocaleDateString())

        let newData = res.data.result.map(el => ({ ...el, dateVal: new Date(el.time) }))
        let currentDate = new Date()
        let startOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1)
        let endOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
        endOfDay.setTime(endOfDay.getTime() - 1)
        console.log("DATES", newData.map(el => el.dateVal.toLocaleDateString() + " " + el.dateVal.toLocaleTimeString()))


        console.log("data", res.data.result);
        // this.setState({agentLogs : res.data.result})
        this.setState({ agentLogs: data })
        document.getElementsByClassName("BackgroundBlur")[0].style.display = 'block';
      }
    }).catch(err => console.error(err))

  }
  render() {
    const { clients } = this.state;
    const { nextPage } = this.state;
    let pageNumber = this.props.match.params.page;
    let finalNumeber = (pageNumber - 1) * 10;
    console.log('pageNumber', pageNumber)
    return (

      <>
        <SiteWrapperSuperAdmin>
          <Page.Content>
            <Grid.Row>
              <Grid.Col lg={12}>
                <Card>
                  <Card.Header>
                    <Card.Title> {changeLanguage(this.props.siteLanguage, 'agents')}</Card.Title>

                    <Form.Group label="" className="ml-auto">
                      <Form.InputGroup className="ml-auto1">
                        <Form.Input placeholder="Search..." className="ml-auto1 "
                          name="search"
                          value={this.state.search}
                          onChange={(e) => this.handleChange(e, 'search')} />
                        <Form.InputGroupAppend>
                          <Button color="primary" className="ml-auto1 btn1a"
                            onClick={(e) => this.search()}>

                            {changeLanguage(this.props.siteLanguage, 'go')}
                          </Button>
                          <Button color="primary" className="ml-auto1 ml-auto10 btn1a"
                            onClick={(e) => this.search(true)}>

                            {changeLanguage(this.props.siteLanguage, 'clear')}
                          </Button>
                        </Form.InputGroupAppend>
                      </Form.InputGroup>
                    </Form.Group>
                  </Card.Header>
                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter table-hover"
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.ColHeader>  {changeLanguage(this.props.siteLanguage, 'sl_no')}</Table.ColHeader>
                        <Table.ColHeader > {changeLanguage(this.props.siteLanguage, 'status')}</Table.ColHeader>
                        <Table.ColHeader >{changeLanguage(this.props.siteLanguage, 'agents_name')}</Table.ColHeader>
                        <Table.ColHeader>{changeLanguage(this.props.siteLanguage, 'email_Id')}</Table.ColHeader>
                        {/* <Table.ColHeader className="center">{changeLanguage(this.props.siteLanguage, 'category')}</Table.ColHeader> */}
                        <Table.ColHeader className="tabl-ce center">ENGAGEMENT SCORE</Table.ColHeader>
                        <Table.ColHeader className="tabl-ce center">Agent Type</Table.ColHeader>
                        <Table.ColHeader className="tabl-ce center">Agent Sub Type</Table.ColHeader>

                        {/* <Table.ColHeader className="tabl-ce center">HOSPITAL</Table.ColHeader> */}
                        {/* <Table.ColHeader className="tabl-ce center">LANGUAGE</Table.ColHeader> */}
                        <Table.ColHeader className="center">{changeLanguage(this.props.siteLanguage, 'action')}</Table.ColHeader>
                        <Table.ColHeader>{changeLanguage(this.props.siteLanguage, 'end_chat')}</Table.ColHeader>

                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {clients.length ?
                        clients.map((client, index) => (
                          <Table.Row key={index}>
                            <Table.Col>{index + 1 + finalNumeber}</Table.Col>
                            {/* <Table.Col className="w-2">
                        <Avatar imageURL="../images/9.jpg" />
                      </Table.Col> */}
                            <Table.Col className="w-10" >
                              {
                                client.isLive ?
                                  <span className="avatar1 alighAdminStatus" style={{cursor:'pointer'}} onClick={() => this.openModal(client.email)}>
                                  </span>
                                  :
                                  <span className="avatar2 alighAdminStatus" style={{cursor:'pointer'}} onClick={() => this.openModal(client.email)}>
                                  </span>
                              }


                            </Table.Col>
                            <Table.Col>{client.name}</Table.Col>
                            <Table.Col>{client.email}</Table.Col>
                            {/* <Table.Col
                            className={
                              "" +
                              (client.category === 'ar' ? " text-nowrap ac2 center" : "text-nowrap ac1 center") +
                              ""
                            }
                          >{client.category}</Table.Col> */}
                            <Table.Col className="tabl-ce">{client.engageNumbers}</Table.Col>
                            <Table.Col className="tabl-ce">{client.agentType == "chatAgent" ? "Chat Agent" : "Lead Agent"}</Table.Col>
                            <Table.Col className="tabl-ce">{client.agentCategory}</Table.Col>

                            {/* <Table.Col className="tabl-ce">{client.hospitalNameEn}</Table.Col> */}
                            {/* <Table.Col className="tabl-ce">{client.language}</Table.Col> */}
                            <Table.Col className="text-nowrap actions chat-w1">
                              <Link to={`/admin/client/edit/${client._id}`} title={changeLanguage(this.props.siteLanguage, 'click_to_edit')}>
                                <Icon prefix="fa" name="edit"></Icon>
                              </Link>
                              {/* <Link onClick={(e) => this.handleViewClient(`/client/${client._id}/dashboard/1`, client)}
                              title="click to view" to="#">
                              <Icon prefix="fa" name="eye"></Icon>
                            </Link> */}
                              <Link to="#" title={client.status === 'active' ? changeLanguage(this.props.siteLanguage, 'click_to_block') : changeLanguage(this.props.siteLanguage, 'click_to_unblock')}
                                onClick={(e) => this.handleBlock(e, client)}>
                                <Icon prefix="fa" name={client.status === 'active' ? 'unlock-alt' : 'lock'}></Icon>
                              </Link>
                              <Link
                                onClick={(e) => this.handleBlock(e, client, true)}
                                to="#" title="click to edit"
                                title={client.isSticky ? changeLanguage(this.props.siteLanguage, 'click_to_unsticky') : changeLanguage(this.props.siteLanguage, 'click_to_sticky')}
                              >
                                <Icon prefix="fe" name={client.isSticky ? 'user-check' : 'user-x'}></Icon>
                              </Link>


                              <Link
                                to="#"
                                title={changeLanguage(this.props.siteLanguage, 'click_to_download_chat')}
                                onClick={(e) => this.openDownloadPopup(e, client._id)}>
                                <Icon prefix="fa" name="download"></Icon>
                              </Link>
                              <Link
                                to={`/adminAgent/change-password/${client._id}`}
                                title={'change password'}
                              >
                                <Icon prefix="fa" name="eye"></Icon>
                              </Link>

                              <Link onClick={(e) => this.handleViewClient(`/client/${client._id}/analytics`, client)}
                                to="#"
                                title={changeLanguage(this.props.siteLanguage, 'click_to_delete')}
                                onClick={(e) => { if (window.confirm('Are you sure to Delete this account?')) { this.removeToAdmin(e, client) }; }}>
                                <Icon prefix="fa" name="trash"></Icon>
                              </Link>

                            </Table.Col>
                            <Table.Col className="chat-w">
                              {<button onClick={(e) => this.endChat(client._id)} className='bg-black marginChatBox chat-btn'>{changeLanguage(this.props.siteLanguage, 'end_chats')}</button>}
                            </Table.Col>
                          </Table.Row>
                        ))
                        :
                        <Table.Row>
                          <Table.Col colSpan={7} >Sorry!  Agents not found.</Table.Col>
                        </Table.Row>
                      }

                    </Table.Body>
                  </Table>
                  {/* pagination */}
                  <div className="form-group" style={{ float: 'right' }}>
                    <label className="form-label">

                    </label>
                    <div className="selectgroup align-to-right paginations">
                      <span>
                        {this.getPagination()}
                      </span>

                      <i
                        className={
                          "fe " +
                          (this.props.match.params.page <= 1 ? "not-active " : "cursorPointer ") +
                          " fe-chevron-left"
                        }
                        onClick={(e) => this.pagination(e, (parseInt(this.props.match.params.page) - 1))}
                        disabled={this.props.match.params.page <= 1}>
                      </i>

                      <i
                        className={
                          "fe " +
                          (!nextPage ? "not-active " : "cursorPointer ") +
                          " fe-chevron-right"
                        }

                        onClick={(e) => this.pagination(e, (parseInt(this.props.match.params.page) + 1))}
                        disabled={!nextPage}></i>
                    </div>
                  </div>
                </Card>
                <DownloadPopup
                  openInfoPopup={this.state.openDownloadPopup}
                  onChange={this.closeDownloadPopup}
                ></DownloadPopup>
              </Grid.Col>
            </Grid.Row>
          </Page.Content>
          <ToastContainer autoClose={5000} position={'bottom-right'} />
        </SiteWrapperSuperAdmin >


        <div style={this.styles.modalWrapper} className="BackgroundBlur" >
          <div style={this.styles.modal}>
            <Card>
              <Card.Header>
                <Card.Title>Agent Logs {this.state.selectedEmail}</Card.Title>
                <Card.Options>

                  <Button color="secondary" size="sm" className="ml-2" onClick={() => this.closeModal()}>
                    Close
                  </Button>
                </Card.Options>
              </Card.Header>
              <Card.Body>
                <label>Start Date</label>
                <input type="date" className="form-control" onChange={(e) => this.setState({ startDate: e.target.value })} />
                <label>End Date</label>

                <input type="date" className="form-control" onChange={(e) => this.setState({ endDate: e.target.value })} />

                <Button color="secondary" size="sm" className="ml-2 mt-2" onClick={() => this.downloadLogs()}>
                  Download
                </Button>
                <Table cards={true} striped={true} responsive={true} className="table-vcenter tablebackground">
                  <Table.Header>
                    <Table.Row>

                      <Table.ColHeader className="capitilize fontSize aligntd"> Sr no. </Table.ColHeader>
                      <Table.ColHeader className="capitilize fontSize aligntd"> Time </Table.ColHeader>
                      <Table.ColHeader className="capitilize fontSize aligntd"> Status </Table.ColHeader>

                    </Table.Row>

                  </Table.Header>
                  <Table.Body>
                    {this.state.agentLogs.length ?
                      this.state.agentLogs.map((log, index) => (
                        <tr>
                          <td className="aligntd"> {index + 1} </td>
                          <td className="aligntd"> {new Date(log.time).toLocaleTimeString()}</td>
                          <td className="aligntd"> {log.status} </td>
                        </tr>
                      )) :
                      <Table.Row>
                        <Table.Col colSpan={7} >Sorry!  no data found.</Table.Col>
                      </Table.Row>
                    }
                  </Table.Body>
                </Table>
              </Card.Body>
            </Card>
          </div>
        </div>

      </>
    )
  }

}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isAuthenticated,
    toast: state.auth.toast,
    clientInfo: state.auth.clientInfo,
    siteLanguage: state.auth.siteLanguage
  }
}

function mapDispatchToProps(dispatch) {
  return {
    adminLoginAsClient: (payload) => {
      dispatch(authActions.adminLoginAsClient(payload))
    },
    setActiveBot: (payload) => {
      dispatch(authActions.setActiveBot(payload))
    }
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Clients));
// export default Clients;
