// @flow

import React from "react";
import { Grid, Card, Button, Icon, Form } from "tabler-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SiteWrapperSuperAdmin from "../SiteWrapper.superadmin.react";
import clientService, { updateServerImage } from "../../services/client";
import Buttonloader from "../../common/Loader/Buttonloader";
import { changeLanguage } from "../../language/index";
import { validate } from '../../validate';
import { getWebbotSettings, setWebbotSettings } from "../../services/user";
// import WebbotSettings from "./webbot-settings";


const validationRules = [
  {
    field: 'name',
    validations: ['required'],
    name: 'Webot Text'

  },
  {
    field: 'color',
    validations: ['required'],
    name: 'Webbot Color'

  },
]

class UpdateData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: {
        style: "success",
        text: "",
      },
      fields: {
        name: '',
        color: '',
      },


      logoFile: '',
      logo_bigFile: '',
      mainMenuFile: '',
      webbotLogo: '',
      webbotBackground: '',



      isLoadingSpecializations: false,
      isLoadingDoctors: false,
      errors: {},
    };
    this.updateDoctors = this.updateDoctors.bind(this);
    this.updateSpecializations = this.updateSpecializations.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);

    this.handleWebbbotUpdate = this.handleWebbbotUpdate.bind(this)

  }

  setLoadingToggle(param, value) {
    switch (param) {
      case "specializations":
        this.setState({ isLoadingSpecializations: value });
        break;
      case "doctors":
        this.setState({ isLoadingDoctors: value });
        break;
      default:
        break;
    }
  }

  updateSpecializations = (e) => {
    e.preventDefault();
    this.setLoadingToggle("specializations", true);
    clientService
      .updateSpecializations()
      .then((response) => {
        toast.success("Specializations updated successfully");
        this.setLoadingToggle("specializations", false);
      })
      .catch((error) => {
        this.setLoadingToggle("specializations", false);
        this.setState(() => ({
          message: { text: error.response.data.message, style: "danger" },
        }));
        setTimeout(() => {
          this.handleNotification();
        }, 3000);
      });
  };

  updateDoctors = () => {
    this.setLoadingToggle("doctors", true);
    clientService
      .updateDoctors()
      .then((response) => {
        toast.success("Doctors updated successfully");
        this.setLoadingToggle("doctors", false);
      })
      .catch((error) => {
        this.setLoadingToggle("doctors", false);
        this.setState(() => ({
          message: { text: error.response.data.message, style: "danger" },
        }));
        setTimeout(() => {
          this.handleNotification();
        }, 3000);
      });
  };


  handleChange = (e, field) => {
    const { fields } = this.state, val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
    //this.validate();
  }

  setLoadingToggle(value) {
    this.setState({ isLoading: value })
  }


  // validate
  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }

  componentDidMount() {
    document.title = "Voiceoc | Update Data";
    this.getWebbot()
  }

  handleNotification() {
    this.setState(() => ({ message: { text: "", style: "" } }));
  }

  async getWebbot(){
    try {
      const res = await getWebbotSettings()
      console.log(res.data ,"GET")
      this.setState({
        fields : res.data
      })
    } catch (error) {
      console.error(error)
      toast.error("Error Get")
    }
  }

  onChangeFile = (event, file) => {
    if (event.target.files[0].type === "image/png") {
      this.setState({ [file]: event.target.files[0] });
    } else {
      toast.error('Please select png image file to upload');
      this.formReset();
      return false;
    }
  }

  async handleWebbbotUpdate(e) {

    try {
      e.preventDefault()
      let obj = {
        ...this.state.fields
      }

      const res = await setWebbotSettings({ webbotSettings : obj })

      if (this.state.webbotLogo) {
        try {
          let form = new FormData()
          form.append('type', 'webbotLogo')
          form.append('media', this.state.webbotLogo)
          const res = await updateServerImage(form)
          toast.success('Webot Logo File Updated')
        } catch (error) {
          console.error(error)
          toast.error('Error Uploading logo file')
        }

      }
      if (this.state.webbotBackground) {
        try {
          let form = new FormData()
          form.append('type', 'webbotBackground')
          form.append('media', this.state.webbotBackground)
          const res = await updateServerImage(form)
          toast.success('Webot Background File Updated')
        } catch (error) {
          console.error(error)
          toast.error('Error Uploading Backgroud file')
        }
      }

      toast.success("Updated")

    } catch (error) {
      console.error(error)
      toast.error("Error")
    }
  }


  render() {
    const { message } = this.state;

    return (
      <SiteWrapperSuperAdmin>
        <Grid.Col lg={5} className="mx-auto ">
          <Card>
            <Card.Header>
              <Card.Title>
                {/* {changeLanguage(this.props.siteLanguage, "change_password")} */}
                Update Data
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {!!message.text ? (
                <div className={"alert alert-" + message.style}>
                  <p>{message.text}</p>
                  <Icon
                    prefix="fa"
                    name="times"
                    onClick={this.handleNotification}
                  ></Icon>
                </div>
              ) : null}
              <div className="tabs" style={{ display: "flex" }}>
                <Button
                  color="primary"
                  className="aligncentre"
                  type="button"
                  onClick={this.updateSpecializations.bind(this)}
                  disabled={this.state.isLoadingSpecializations}
                >
                  {/* {changeLanguage(this.props.siteLanguage, "change")} */}
                  Update Specializations
                </Button>
                <Button
                  color="primary"
                  className="aligncentre"
                  type="button"
                  onClick={this.updateDoctors.bind(this)}
                  disabled={this.state.isLoadingDoctors}
                >
                  {/* {changeLanguage(this.props.siteLanguage, "change")} */}
                  Update Doctors
                </Button>
              </div>
              <div className="p-2">

                <div className="row mt-5">
                  <div className="col">
                    <h4>Webbot settings</h4>

                    <div className="row">
                      <div className="col">

                        <form onSubmit={this.handleWebbbotUpdate}>

                          <Form.Group label={'Webbot Text'} >
                            <Form.Input name="Webbot Text"
                              type="text"
                              placeholder={'Webbot Name'}
                              onChange={(e) => this.handleChange(e, 'name')}
                              value={this.state.fields.name}
                            />
                            <label style={{ display: this.state.errors.name ? 'block' : 'none' }} className="error">{this.state.errors.name}</label>
                          </Form.Group>

                          <Form.Group label={'Webbot Background Color Hex'} >
                            <Form.Input
                              name="Webbot Background Color Hex"
                              type="color"
                              placeholder={'Webbot Background Color Hex'}
                              onChange={(e) => this.handleChange(e, 'color')}
                              value={this.state.fields.color}
                            />
                            <label style={{ display: this.state.errors.color ? 'block' : 'none' }} className="error">{this.state.errors.color}</label>
                          </Form.Group>

                          <div className="col-sm-12">
                            <span className="form-label">
                              WebbotLogo Upload:-
                            </span>
                            <div className="file">
                              <span className="file-custom">
                                <input id="image" name="image" type="file" accept="image/png" onChange={(e) => this.onChangeFile(e, 'webbotLogo')} />
                              </span>
                            </div>
                          </div>

                          <div className="col-sm-12">
                            <span className="form-label">
                              WebbotBackgroud Upload:-
                            </span>
                            <div className="file">
                              <span className="file-custom">
                                <input id="image" name="image" type="file" accept="image/png" onChange={(e) => this.onChangeFile(e, 'webbotBackground')} />
                              </span>
                            </div>
                          </div>

                          {!this.state.isLoading ?
                            <Button color="primary btn-block" type="submit">Update Webbot Settings</Button>
                            :
                            <Buttonloader loading={this.state.isLoading}></Buttonloader>
                          }

                        </form>

                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </Card.Body>
          </Card>
        </Grid.Col>
        <ToastContainer autoClose={5000} position={"bottom-right"} />
      </SiteWrapperSuperAdmin>
    );
  }
}

export default UpdateData;
